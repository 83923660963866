import '../../plugins/polyfill.js';
import {setRequest} from '../../../api/index.js';
import {loadAllPlugins} from '../../plus/index.js';
import {getAvatarColor} from '../../utils/index.js';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VField from './utils/v-field';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
import vDialogDrag from '../../utils/directive/dialog-drag.js';
import vDialogResize from '../../utils/directive/dialog-resize.js';

Vue.use(VueClipboard);
Vue.directive(vDialogDrag.name, vDialogDrag);
Vue.directive(vDialogResize.name, vDialogResize);
/**
 * 集中处理接口
 */
import {request} from './service/api/request.js';

setRequest(request);

Vue.use(infiniteScroll)
Vue.use(VField);

loadAllPlugins();

const momentFormat = (value, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) return ''
  return moment(value).format(format)
}

Vue.mixin({
  computed: {

  },
  methods: {
    moment: momentFormat,
    closePage(msg) {
      msg && this.$message.warning(msg);
      this.$router.go(-1);
    },
    getAppPopupContainer() {
      // 很多公共Popup组件都用到这个方法，目的是让浮层随着页面滚动，如果要把组件拷贝到其他项目，一定要根据项目逻辑重构这个mixin
      return document.querySelector(this.appPopupContainerSelector || '.index-content') || document.body;
    },
    getAvatarColor
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');
