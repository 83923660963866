import {formatDuration, getYear} from './util.js';
import {throttle, rafThrottle} from './throttle.js';
import storage from 'store';

const Utils = {
  throttle,
  rafThrottle
};

export function getDate(date, emptyValue = '') {
  if (!date) return emptyValue;
  return date instanceof Date ? date : new Date(typeof date === 'number' ? date : date.length < 20 ? date.replace(/-|\./g, '/') : date || 0)
}

export function fromNow(date) {
  date = getDate(date);
  let oldTime = date.getTime();
  let newTime = Date.now();
  let diff = Math.floor(newTime - oldTime);
  const unit = diff < 0 ? '后' : '前';
  if (diff < 0) diff = -diff;
  if (diff < 10000 && unit === '前') {
    return '刚刚'
  } else if (diff < 60000) {
    return ~~(diff / 1000) + '秒' + unit;
  } else if (diff < 3600000) {
    return ~~(diff / 60000) + '分钟' + unit;
  } else if (diff < 86400000) {
    return ~~(diff / 3600000) + '小时' + unit;
  } else if (diff < 2592000000) {
    return ~~(diff / 86400000) + '天' + unit;
  } else if (diff < 31104000000) {
    return ~~(diff / 2592000000) + '个月' + unit;
  } else {
    return ~~(diff / 31104000000) + '年' + unit;
  }
}

/**
 * 抗并发请求缓存队列
 * @param key     唯一键名 用来识别请求的接口和参数
 * @param request 要请求的方法 如果要传参自行二次封装 () => agentSettingGet({params: 1})
 * @param done    成功监听 return的数据会返回给队列所有请求
 * @returns {Promise<>}
 */
export const requestQueue = (function () {
  const requestQueueCache = {};
  return function requestQueue(key, request, done = undefined) {
    return new Promise((resolve, reject) => {
      if (!requestQueueCache[key]) {
        requestQueueCache[key] = [];
        request().then(res => {
          const resData = done ? done(res) : res;
          for (const req of (requestQueueCache[key] || [])) {
            req.resolve(resData)
          }
          delete requestQueueCache[key];
        }).catch(err => {
          for (const req of (requestQueueCache[key] || [])) {
            req.reject(err)
          }
          delete requestQueueCache[key];
        })
      }
      requestQueueCache[key].push({resolve, reject})
    })
  }
})();

/**
 * 带本地缓存的请求 与后端协定updatedAt
 * 用这个接口请求只剩下res.data
 * @param key        唯一键名 用来识别请求的接口和参数
 * @param request    要请求的方法 如果要传参自行二次封装 () => agentSettingGet({params: 1})
 * @param change     成功监听 request.res.data 如果有本地缓存，并且跟服务端不一致的情况触发
 * @param fistChange 第一次取值是否触发change
 * @type {(function(*=, *=, {reread?: *, change?: *, fistChange?: *}=): (Promise<*>))|*}
 */
export const storageRequest = (function () {
  const cache = {};
  return function storageRequest(key, request, {reread = false, change, fistChange = true} = {}) {
    if (cache[key] && !reread) {
      fistChange && change && change(cache[key]);
      return Promise.resolve(cache[key])
    }
    return new Promise((resolve, reject) => {
      const storeData = storage.get(`storageRequest-${key}`);
      if (storeData) {
        resolve(storeData);
        cache[key] = storeData;
        fistChange && change && change(storeData);
        requestQueue(key, () => request({updatedAt: cache[key].updatedAt})).then(res => {
          if (typeof res.data === 'object') {
            cache[key] = res.data;
            storage.set(`storageRequest-${key}`, res.data);
            change && change(res.data);
          }
        }).catch(reject);
      } else {
        requestQueue(key, request).then(res => {
          if (typeof res.data === 'object') {
            cache[key] = res.data;
            storage.set(`storageRequest-${key}`, res.data);
            resolve(res.data);
            fistChange && change && change(storeData);
          }
        }).catch(reject);
      }
    })
  }
})();

export const arrayChunk = (arr, size) => Array.from(
  {length: Math.ceil(arr.length / size)},
  (v, i) => arr.slice(i * size, i * size + size)
);

/**
 * 字符串占位长度
 * @param str
 * @returns {number}
 */
export function strLen(str) {
  let l = str.length, len = 0;
  for (let i = 0; i < l; i++) {
    len += (str.charCodeAt(i) & 0xff00) !== 0 ? 2 : 1;
  }
  return len;
}

export function getAvatarColor(name) {
  const colors = [
    '#eea09e', '#7eb2d7', '#c8c480', '#e788c0',
    '#e79178', '#84cab0', '#62b4cc', '#f09896',
    '#e788c0', '#bc9de0', '#c5c273', '#7db3d9',
    '#51adfa', '#e9a67c', '#91c66a', '#d5a74f'
    /* '#52c41a', '#409EFF', '#faad14', '#2986FF',
     '#0095EE', '#05A4B6', '#00A696', '#38AA43',
     '#7BB63C', '#9BD100', '#F93920', '#F93920',
     '#E91E63', '#9C27B0', '#6A3AC7', '#3F51B5',
     '#0077FA', '#FAC800', '#F0B114', '#FC8800',
     '#4bdb9b', '#7f3bf5', '#4e83fd'*/
  ];
  const index = (name || '').toString().split('').reduce((v, str) => v + str.charCodeAt(), 0) % colors.length;
  return colors[index];
}

export function ossProcessHandle(src, {ossProcess = true} = {}) {
  if (src && ossProcess && !src.includes('?')) {
    let valid = /[\s\S]{8}-[\s\S]{4}-[\s\S]{4}-[\s\S]{4}-[\s\S]{12}/.test(src); // 旧版本的GUID模式
    if (!valid) {
      const name = src.split('/').pop().split('.')[0];                          // 新版本的11位有序nanoid模式
      valid = name.length === 11;
    }
    if (valid) {
      const process = typeof ossProcess === 'string' ? ossProcess : 'image/resize,w_200,h_200';
      return src + `?x-oss-process=${process}`;
    }
  }
  return src;
}

export function urlQueryGet(url) {
  let index = url.indexOf('?');
  if (index > -1) url = url.substr(index + 1);
  index = url.indexOf('#');
  if (index > -1) url = url.substr(0, index);
  return url.split('&').map(v => v.split('=')).reduce((obj, ary) => void (obj[ary[0]] = decodeURIComponent(ary[1])) || obj, {});
}

export function getStringByteLength(value) {
  let str = value || '';
  let bytes = 0;
  for (let i = 0, n = str.length; i < n; i++) {
    let c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      bytes += 1;
    } else {
      bytes += 2;
    }
  }
  return bytes;
}

/**
 * 简历信息格式化
 * @param base
 * @returns {[{icon: string, value: string}, {icon: string, value}, {icon: (string), value: string}]}
 */
export function formatResumeLabels(base) {
  const age = getYear(base.birthday) + '岁';
  const degree = base.degree;
  let year = ''
  if (base.jobStatus) {
    year = base.workingDate ? formatDuration(base.workingDate, new Date(), {hiddenMonth: true}) : '无经验';
  } else {
    const nowYear = new Date().getFullYear();
    if (base.graduation) {
      if (base.graduation >= nowYear - 1) {
        year = base.graduation.toString().substr(2) + '年应届生'
      } else {
        year = base.graduation.toString().substr(2) + '年毕业'
      }
    } else {
      year = '学生'
    }
  }
  return [
    {value: age, icon: 'tc-icon tc-birthday'},
    {value: degree, icon: 'tc-icon tc-degree'},
    {value: year, icon: 'tc-icon tc-tool'}
  ]
}

export function getRandom(num) {
  return Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, num - 1));
}

export default Utils;
