import {message as Message} from 'ant-design-vue'
import LoadingBar from '../../../components/loading-bar/src/index.js';
import store from '../store';

let loginInit = false;

function routerValid({meta}) {
  if (meta.userType) {
    const types = Array.isArray(meta.userType) ? meta.userType : [meta.userType];
    return types.includes(store.getters.user.type);
  }
  if (meta.validate) {
    return meta.validate(store.getters.user);
  }
  return true;
}

/**
 * 全局路由守卫
 */
export default (router) => {

  router.beforeEach(async (to, from, next) => {
    next();
  });

  router.afterEach(to => {
    if (to.meta.title) {
      const title = typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title;
      document.title = store.state.titlePrefix + title;
    } else {
      document.title = store.state.title;
    }
    LoadingBar.finish();
  });

}
