import Vue from 'vue'
import {
  Descriptions,
  Breadcrumb,
  Button,
  BackTop,
  Spin,
  Card,
  ConfigProvider,
  Cascader,
  Empty,
  Row,
  Col,
  Tag,
  Form,
  Table,
  Tooltip,
  Icon,
  Input,
  InputNumber,
  Select,
  Layout,
  LocaleProvider,
  message,
  Pagination,
  Divider,
  Avatar,
  Drawer,
  Checkbox,
  Modal,
  Tabs,
  Collapse,
  Dropdown,
  Menu,
  FormModel,
  DatePicker,
  List,
  Radio,
  notification,
  Popconfirm,
  Timeline,
  Upload,
  Popover,
  Steps,
  Rate,
  Switch,
  TreeSelect,
  AutoComplete,
  TimePicker,
  Badge,
  Skeleton,
  Progress,
  Slider,
  Affix,
  Statistic,
  Alert,
  PageHeader,
  Tree,
  Carousel,
  Result
} from 'ant-design-vue'

export function $confirm(options = {}) {
  return new Promise((resolve, reject) => {
    if (options.confirmText) options.okText = options.confirmText;
    if (options.confirmColor) {
      if (!options.okButtonProps) options.okButtonProps = {};
      options.okButtonProps.style = {color: options.confirmColor};
    }
    if (options.confirmType) {
      if (!options.okButtonProps) options.okButtonProps = {};
      if (!options.okButtonProps.props) options.okButtonProps.props = {};
      options.okButtonProps.props.type = options.confirmType;
    }
    options.zIndex = 1099;
    let _onOk = options.onOk,
      _onCancel = options.onCancel
    options.onOk = () => {
      const okRes = _onOk ? _onOk() : undefined
      if (okRes && okRes.then) {
        return okRes.then(() => {
          resolve()
          return true
        })
      }
      resolve()
    }
    options.onCancel = () => {
      const cancelRes = _onCancel ? _onCancel() : undefined
      if (cancelRes && cancelRes.then) {
        return cancelRes.then(() => {
          reject()
          return true
        })
      }
      reject()
    }
    Modal.confirm(options)
  })
}

export default function loadAntdComponent(app) {
  Vue.use(Descriptions)
  Vue.use(Breadcrumb)
  Vue.use(Button)
  Vue.use(BackTop)
  Vue.use(Spin)
  Vue.use(Card)
  Vue.use(ConfigProvider)
  Vue.use(Cascader)
  Vue.use(Empty)
  Vue.use(Row)
  Vue.use(Col)
  Vue.use(Tag)
  Vue.use(Form)
  Vue.use(Table)
  Vue.use(Tooltip)
  Vue.use(Input)
  Vue.use(InputNumber)
  Vue.use(Select)
  Vue.use(Layout)
  Vue.use(LocaleProvider)
  Vue.use(Pagination)
  Vue.use(Divider)
  Vue.use(Icon)
  Vue.use(Avatar)
  Vue.use(Drawer)
  Vue.use(Checkbox)
  Vue.use(Modal)
  Vue.use(Tabs)
  Vue.use(Collapse)
  Vue.use(Dropdown)
  Vue.use(Menu)
  Vue.use(FormModel)
  Vue.use(DatePicker)
  Vue.use(List)
  Vue.use(Radio)
  Vue.use(Tooltip)
  Vue.use(ConfigProvider)
  Vue.use(notification)
  Vue.use(Popconfirm)
  Vue.use(Timeline)
  Vue.use(Upload)
  Vue.use(Popover)
  Vue.use(Steps)
  Vue.use(Rate)
  Vue.use(Switch)
  Vue.use(TreeSelect)
  Vue.use(AutoComplete)
  Vue.use(TimePicker)
  Vue.use(Badge)
  Vue.use(Skeleton)
  Vue.use(Progress)
  Vue.use(Slider)
  Vue.use(Affix)
  Vue.use(Statistic)
  Vue.use(Alert)
  Vue.use(PageHeader)
  Vue.use(Tree)
  Vue.use(Carousel)
  Vue.use(Result)
  Vue.prototype.$message = message
  Vue.prototype.$notification = notification

  /**
   * 扩展 让$confirm 支持 Promise 方式
   * @param options
   * @returns {Promise<unknown>}
   */
  Vue.prototype.$confirm = $confirm;

  notification.config({
    placement: 'center',
    top: '50px',
    duration: 4.5
  });
}
