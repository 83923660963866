import {requestQueue} from '../../../../utils/index.js';
import {JSEncrypt} from './rsa-long.js';

const requestRsa = {

  jsEncrypt: {
    publicKey: '',
    privateKey: '',
    encrypt: null,
    decrypt: null
  },

  async encryptGet({reread = false, request} = {}) {
    if (!reread && requestRsa.jsEncrypt.encrypt) {
      return Promise.resolve(requestRsa.jsEncrypt)
    }
    return requestQueue('publicKeyGet', () => request({api: '/api-auth/rsa/keyGet'}), res => {
      requestRsa.jsEncrypt.publicKey = res.data.publicKey;
      requestRsa.jsEncrypt.privateKey = res.data.privateKey;
      requestRsa.jsEncrypt.encrypt = new JSEncrypt();
      requestRsa.jsEncrypt.encrypt.setPublicKey(res.data.publicKey);
      requestRsa.jsEncrypt.decrypt = new JSEncrypt();
      requestRsa.jsEncrypt.decrypt.setPrivateKey(res.data.privateKey);
      return requestRsa.jsEncrypt;
    });
  },

  encrypt(data, {rsa = true, long = true, request} = {}) {
    if (!rsa) {
      return Promise.resolve(data)
    }
    return requestRsa.encryptGet({request}).then(({encrypt}) => {
      const string = typeof data === 'object' ? JSON.stringify(data) : data;
      let RSA = long ? encrypt.encryptLong(string) : encrypt.encrypt(string);
      return {RSA};
    })
  },

  decrypt(data, {long = true, request}) {
    if (!data) {
      return Promise.resolve(data)
    }
    return requestRsa.encryptGet({request}).then(({decrypt}) => {
      const deStr = long ? decrypt.decryptLong(data) : decrypt.decrypt(data);
      try {
        return JSON.parse(deStr)
      } catch (err) {
        return deStr;
      }
    })
  }

}

export default requestRsa;
