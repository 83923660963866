import {hasClassDeep} from '../dom.js';


/**
 *  v-dialog-drag: 弹窗拖拽
 *  v-dialog-drag="{dragClass: '', contentClass: '', excludeClass: '', disabled: () => true}" // 自定义拖动的元素
 *  Vue.directive(directive.name, directive)
 */
export default {
  name: 'dialog-drag',
  bind(el, bindValue) {
    const dragClass = (bindValue.value || {}).dragClass || '.ant-modal-header';
    const contentCls = (bindValue.value || {}).contentClass || '.ant-modal-content';
    const excludeClass = (bindValue.value || {}).excludeClass || '';
    const safeArea = (bindValue.value || {}).safeArea || false;
    const dialogHeaderEl = el.querySelector(dragClass);
    const dragDom = el.querySelector(contentCls);
    if (!dialogHeaderEl) return false;
    dialogHeaderEl.style.cursor = 'move';
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    let top = undefined;

    dialogHeaderEl.onmousedown = (e) => {
      top = undefined;
      if (bindValue.value && typeof bindValue.value.disabled === 'function') {
        if (bindValue.value.disabled()) {
          return true;
        }
      }
      if (excludeClass && hasClassDeep(e.target, excludeClass)) {
        return true;
      }
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - (dragClass ? 0 : dialogHeaderEl.offsetLeft);
      const disY = e.clientY - (dragClass ? 0 : dialogHeaderEl.offsetTop);

      // 获取到的值带px 正则匹配替换
      let styL, styT;

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        // eslint-disable-next-line no-useless-escape
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        // eslint-disable-next-line no-useless-escape
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX;
        const t = e.clientY - disY;

        // 移动当前元素
        dragDom.style.left = `${l + styL}px`;
        dragDom.style.top = `${t + styT}px`;

        top = t + styT;
        //将此时的位置传出去
        //binding.value({x:e.pageX,y:e.pageY})
      };

      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        if (safeArea && top < 0) {
          dragDom.style.top = `0px`;
        }
      };
    };
  }
};
