import Vue from 'vue';

/**
 需要在App.vue中渲染
 <component
 v-for="item in publicComponents"
 v-bind="item.attrs"
 v-on="item.listeners"
 :key="item.key"
 :is="item.componentName"
 />

 */
class PublicComponent {

  constructor({timeout = 60000, components = []} = {}) {
    this.components = components
    this.timeout = timeout;
  }

  /**
   * 2021/7/20 重新设计一套异步加载全局组件的方法
   * 2021/9/20 封装为Class
   * 被调用的组件必须暴露mounted钩子
   * mounted() {
      this.$emit('mounted', this, opt: {activation: ()=> {});
    }
   * 被调用的组件在隐藏时必须 $emit('close', this); 否则会一直创建新的
   * 被调用的组件必须有open函数作为入口 并返回Promise
   *
   * @param params    组件的open参数
   * @param component 组件异步加载方法
   * @param attrs     给组件的额外参数
   * @param listeners 监听
   * @param unClear   是否不被清除
   * @param reusable  是否可复用 默认是 组件关闭了下次打开还使用这个组件的open
   * @param indexOfComponent   希望渲染的组件在这个实例组件后面
   * @param primaryKey         主键 同类型同主键在显示时不会重复打开
   * @returns {Promise<>}
   */
  open(params, {
    component,
    attrs = {},
    listeners = {},
    unClear = false,
    reusable = true,
    indexOfComponent,
    primaryKey
  }) {
    return new Promise((resolve, reject) => {
      const mounted = listeners.mounted;
      const close = listeners.close;
      // 主键缓存
      let historyComponent = this.historyPrimaryKeyValid({component, primaryKey})
      if (historyComponent) {
        if (historyComponent.opt && historyComponent.opt.activation) {
          historyComponent.opt.activation();
        }
        historyComponent.resolve.list.push(resolve);
        historyComponent.reject.list.push(reject);
      } else {
        // 复用组件
        historyComponent = this.historyComponentGet({component, reusable, indexOfComponent});
        if (historyComponent) {
          historyComponent.visible = true;
          historyComponent.params = params;
          historyComponent.primaryKey = primaryKey;
          historyComponent.resolve.list.push(resolve);
          historyComponent.reject.list.push(reject);
          historyComponent.vm.open(params).then(historyComponent.resolve.exec).catch(historyComponent.reject.exec);
          if (!unClear) {
            this.setComponentTimeout(historyComponent);
          }
        } else {
          // 组件内必须触发 mounted
          listeners.mounted = (vm, opt) => {
            historyComponent.vm = vm;
            historyComponent.opt = opt;
            if (mounted) mounted(vm);
            vm.open(params).then(historyComponent.resolve.exec).catch(historyComponent.reject.exec);
          }
          // 组件内隐藏了一定要触发 close
          listeners.close = () => {
            if (close) close();
            historyComponent.visible = false;
            historyComponent.primaryKey = undefined;
            setTimeout(() => {
              historyComponent.resolve.list = [];
              historyComponent.reject.list = [];
            }, 100)
            if (!reusable) {
              this.destroyComponent(historyComponent);
            }
          }
          const loadComponent = component => {
            if (component.res) {
              return Promise.resolve(component.res);
            }
            //this.loading = true;
            return component().then((res) => {
              component.res = res;
              Vue.component(res.default.name, res.default);
              return res;
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              //this.loading = false;
            })
          }
          loadComponent(component).then((res) => {
            historyComponent = {
              visible: true,
              component,
              componentName: res.default.name,
              attrs,
              listeners,
              vm: null,
              key: Date.now() + '-' + this.components.length,
              time: 0,
              params,
              primaryKey,
              resolve: {
                list: [resolve],
                exec: (e) => {
                  for (const fun of historyComponent.resolve.list) {
                    fun && fun(e);
                  }
                  historyComponent.resolve.list = [];
                }
              },
              reject: {
                list: [reject],
                exec: (e) => {
                  for (const fun of historyComponent.reject.list) {
                    fun && fun(e);
                  }
                  historyComponent.reject.list = [];
                }
              }
            }
            this.components.push(historyComponent);
            if (!unClear) {
              this.setComponentTimeout(historyComponent);
            }
          })
        }
      }
    })
  }

  destroyComponent(historyComponent) {
    const index = this.components.indexOf(historyComponent);
    if (index > -1) {
      this.components.splice(index, 1);
    }
  }

  historyPrimaryKeyValid({component, primaryKey}) {
    return primaryKey ? this.components.find(v => v.visible && v.component === component && v.primaryKey === primaryKey) : undefined;
  }

  /**
   * 历史组件复用逻辑单独抽离完善
   * @param component          当前的组件
   * @param reusable           是否可复用
   * @param indexOfComponent   希望渲染的组件在这个实例组件后面
   */
  historyComponentGet({component, reusable, indexOfComponent}) {
    // 不可复用 直接传回空
    if (!reusable) return undefined;
    let index = -1;
    // 判定
    if (indexOfComponent) {
      index = this.components.findIndex(v => {
        let vmComponent = indexOfComponent
        while (vmComponent) {
          if (vmComponent === v.vm) {
            return true;
          } else {
            vmComponent = vmComponent.$parent;
          }
        }
        return false;
      })
    }
    return this.components.find((v, i) => {
      return !v.visible && v.component === component && (index === -1 || index < i);
    })
  }

  /**
   * 每一分钟清除关闭组件的缓存，避免数据太多卡顿或内存泄露
   * @param historyComponent
   */
  setComponentTimeout(historyComponent) {
    if (historyComponent.time) {
      clearTimeout(historyComponent.time);
      historyComponent.time = 0;
    }
    historyComponent.time = setTimeout(() => {
      if (!historyComponent.visible) {
        this.destroyComponent(historyComponent);
      } else {
        this.setComponentTimeout(historyComponent);
      }
    }, this.timeout)
  }
}

export default PublicComponent;
