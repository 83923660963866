<template>
  <transition name="fade">
    <div v-show="show" :class="classes" :style="outerStyles">
      <div :class="innerClasses" :style="styles"/>
    </div>
  </transition>
</template>
<script>
  const prefixCls = 'ea-loading-bar';

  export default {
    name: 'EaLoadingBar',
    props: {
      color: {
        type: String,
        default: 'primary',
      },
      failedColor: {
        type: String,
        default: 'error',
      },
      height: {
        type: Number,
        default: 2,
      },
    },
    data() {
      return {
        percent: 0,
        status: 'success',
        show: false,
      };
    },
    computed: {
      classes() {
        return `${prefixCls}`;
      },
      innerClasses() {
        return [
          `${prefixCls}-inner`,
          {
            [`${prefixCls}-inner-color-primary`]: this.color === 'primary' && this.status === 'success',
            [`${prefixCls}-inner-failed-color-error`]: this.failedColor === 'error' && this.status === 'error',
          },
        ];
      },
      outerStyles() {
        return {
          height: `${this.height}px`,
        };
      },
      styles() {
        let style = {
          width: `${this.percent}%`,
          height: `${this.height}px`,
        };
        if (this.color !== 'primary' && this.status === 'success') {
          style.backgroundColor = this.color;
        }
        if (this.failedColor !== 'error' && this.status === 'error') {
          style.backgroundColor = this.failedColor;
        }
        return style;
      },
    },
  };
</script>
<style scoped lang="less">
@import '../../../style/var';

.ea-loading-bar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.ea-loading-bar-inner {
  transition: width .2s linear;
}

.ea-loading-bar-inner-color-primary {
  background-color: @color-primary;
}

.ea-loading-bar-inner-failed-color-error {
  background-color: @color-red;
}
</style>
