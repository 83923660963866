let appStore = undefined;
let appRequest = undefined;
let appRouter = undefined;

/**
 * 预定义，不同项目要把内部实现的request导进来
 */
const request = (params = {}) => {
  if (params && appStore) params.$store = appStore
  return appRequest ? appRequest(params) : Promise.resolve({code: 200, data: undefined, msg: ''});
};

/**
 * 要把各个项目内实现的请求方法导进来
 * @param requestFun function
 */
export function setRequest(requestFun) {
  appRequest = requestFun;
}

export function setStore(store) {
  appStore = store;
}

export function getStore() {
  return appStore;
}

/**
 * simple request
 * 封装 request
 * @param api
 * @param defOptions
 * @returns {function(*=, *=): Promise<{code, msg, data}>}
 */
const sr = (api, defOptions = {}) => (data, options = {}) => request({
  api,
  data,
  ...options,
  ...defOptions
})

export {request, sr};

