import JSEncrypt from './jsencrypt.js';

const BI_RM = '0123456789abcdefghijklmnopqrstuvwxyz';
const b64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const b64pad = '=';

// 分段解密，支持中文
JSEncrypt.prototype.decryptLong = function (string) {
  const key = this.getKey();
  // 解密长度=key size.hex2b64结果是每字节每两字符，所以直接*2
  const maxLength = ((key.n.bitLength() + 7) >> 3) * 2;
  try {
    const hexString = b64tohex(string);
    const rex = new RegExp('.{1,' + maxLength + '}', 'g');
    const subStrArray = hexString.match(rex);
    return decodeURIComponent((subStrArray || []).reduce((str, entry) => `${str}${key.decrypt(entry)}`, ''));
  } catch (ex) {
    console.log('decryptLong', ex)
    return false;
  }
};

// 分段加密，支持中文
JSEncrypt.prototype.encryptLong = function (string) {
  string = encodeURIComponent(string);
  const key = this.getKey();
  // 根据key所能编码的最大长度来定分段长度。key size - 11：11字节随机padding使每次加密结果都不同。
  const maxLength = ((key.n.bitLength() + 7) >> 3) - 11;
  try {
    const len = string.length;
    let subStr = '', encryptedString = '';
    let subStart = 0, subEnd = 0;
    let bitLen = 0, tmpPoint = 0;
    for (let i = 0; i < len; i++) {
      //js 是使用 Unicode 编码的，每个字符所占用的字节数不同
      const charCode = string.charCodeAt(i);
      if (charCode <= 0x007f) {
        bitLen += 1;
      } else if (charCode <= 0x07ff) {
        bitLen += 2;
      } else if (charCode <= 0xffff) {
        bitLen += 3;
      } else {
        bitLen += 4;
      }
      //字节数到达上限，获取子字符串加密并追加到总字符串后。更新下一个字符串起始位置及字节计算。
      if (bitLen > maxLength) {
        subStr = string.substring(subStart, subEnd);
        encryptedString += key.encrypt(subStr);
        subStart = subEnd;
        bitLen = bitLen - tmpPoint;
      } else {
        subEnd = i;
        tmpPoint = bitLen;
      }
    }
    subStr = string.substring(subStart, len)
    encryptedString += key.encrypt(subStr);
    return hex2b64(encryptedString);
  } catch (ex) {
    return false;
  }
};

function int2char(n) {
  return BI_RM.charAt(n);
}

function b64tohex(s) {
  let ret = ''
  let i;
  let k = 0; // b64 state, 0-3
  let slop;
  for (i = 0; i < s.length; ++i) {
    if (s.charAt(i) === b64pad) break;
    let v = b64map.indexOf(s.charAt(i));
    if (v < 0) continue;
    if (k === 0) {
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 1;
    } else if (k === 1) {
      ret += int2char((slop << 2) | (v >> 4));
      slop = v & 0xf;
      k = 2;
    } else if (k === 2) {
      ret += int2char(slop);
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 3;
    } else {
      ret += int2char((slop << 2) | (v >> 4));
      ret += int2char(v & 0xf);
      k = 0;
    }
  }
  if (k === 1) ret += int2char(slop << 2);
  return ret;
}

function hex2b64(h) {
  let i;
  let c;
  let ret = '';
  for (i = 0; i + 3 <= h.length; i += 3) {
    c = parseInt(h.substring(i, i + 3), 16);
    ret += b64map.charAt(c >> 6) + b64map.charAt(c & 63);
  }
  if (i + 1 === h.length) {
    c = parseInt(h.substring(i, i + 1), 16);
    ret += b64map.charAt(c << 2);
  } else if (i + 2 === h.length) {
    c = parseInt(h.substring(i, i + 2), 16);
    ret += b64map.charAt(c >> 2) + b64map.charAt((c & 3) << 4);
  }
  while ((ret.length & 3) > 0) ret += b64pad;
  return ret;
}

export {JSEncrypt}

