const isDev = true;

const option = !isDev ? {
  // 真实要部署的应用
  url: 'https://api.tccms.cn'
} : {
  // dev 应用数据
  url: 'https://tcy.asseek.com'
}
const config = {
  app: {
    name: '淘才云SAAS'
  },
  server: {
    node: {
      url: option.url,
      saasUrl: option.url,
    },
    timeout: 10000,
    successCode: 200,     // 后台成功状态码 要是改成0了这边改一下就行
  },

  storage: {
    timeout: 7 * 24 * 60 * 60 * 1000,         // 缓存数据有效期ms
    authorizationKey: 'authorization-saas-gateway'    // 不同应用要配置不同的key 不然开发阶段互相覆盖
  },
}

export default config
