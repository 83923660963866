<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
      <tc-image-viewer
        v-if="imgViewer.visible"
        :z-index="imgViewer.zIndex"
        :initial-index="imgViewer.imageIndex"
        :url-list="imgViewer.imageList"
        :on-close="() => (imgViewer.visible = false)"
      />
      <component
        v-for="item in publicComponents"
        v-bind="item.attrs"
        v-on="item.listeners"
        :key="item.key"
        :is="item.componentName"
      />
    </div>
  </a-config-provider>
</template>

<script>
import TcImageViewer from '../../components/image/ImageViewer.vue';
import {publicComponents} from './utils/public-component.js';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Vue from 'vue'

export default {
  name: 'App',
  components: {TcImageViewer},
  computed: {
    locale() {
      return zh_CN
    }
  },
  provide: {
    appPopupContainerSelector: '.index-content'
  },
  data() {
    return {
      imgViewer: {
        visible: false,
        imageList: [],
        zIndex: 5000,
        imageIndex: 0
      },
      publicComponents
    }
  },
  methods: {
    viewer(imageList, imageIndex) {
      this.imgViewer.imageList = imageList;
      this.imgViewer.imageIndex = imageIndex;
      this.imgViewer.visible = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      /**
       * $viewer 全局图片预览方法
       * @param imageList    array    图片列表 [src, src, ...]
       * @param imageIndex   number   初始显示索引
       */
      Vue.prototype.$viewer = this.viewer;
    })
  }
}
</script>

<style lang="less">
@import "./style/reset.less";
@import "./style/common.less";
@import "./style/private.less";

#app {
  font-family: PingFangSC-Regular, PingFang SC, Microsoft Yahei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: @text-main;
  font-size: 13px;
  box-sizing: border-box;
}

pre {
  font-family: PingFangSC-Regular, PingFang SC, Microsoft Yahei, Avenir, Helvetica, Arial, sans-serif;
  color: @text-main !important;
  font-size: 13px;
}

.icon-svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
