const fromEntries = (arrArg) => {
  // Map
  if (Object.prototype.toString.call(arrArg) === '[object Map]') {
    const resMap = {};
    for (const key of arrArg.keys()) {
      resMap[key] = arrArg.get(key);
    }
    return resMap;
  }
  // array
  if (Array.isArray(arrArg)) {
    const resArr = {}
    arrArg.map(([key, value]) => {
      resArr[key] = value
    })
    return resArr
  }
  throw 'Uncaught TypeError: argument is not iterable';
}

const entries = (inArg) => {
  if (Array.isArray(inArg)) {
    return inArg.map((x, index) => [`${index}`, x])
  }
  if (Object.prototype.toString.call(inArg) === `[object Object]`) {
    return Object.keys(inArg).map(y => [y, inArg[y]])
  }
  if (typeof inArg === 'number') return []
  throw 'Cannot convert argument to object'
}

// polyfill
if (!Object.fromEntries) Object.fromEntries = fromEntries
if (!Object.entries) Object.entries = entries;
