const Index = () =>
  import(/* webpackChunkName: "index" */ '../../views/Index.vue');

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  }
];

export default routes;
