/**
 *  v-dialog-resize: 弹窗大小调整
 *  v-dialog-resize="{contentClass: '', disabled: () => true, bindBefore, setBefore}" // 要放大缩小的dom
 *  Vue.directive(directive.name, directive)
 */
export default {
  name: 'dialog-resize',
  bind(el, bindValue) {
    const options = bindValue.value || {}
    const contentCls = options.contentClass || '.resize';
    const widthUnit = options.widthUnit || 'px';
    const resizeDom = el.querySelector(contentCls);
    if (!resizeDom) return false;
    //resizeDom.style.position = 'relative';
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = resizeDom.currentStyle || window.getComputedStyle(resizeDom, null);
    // 执行style设置
    const execSetStyle = function (el, list) {
      let isChange = false;
      for (const ary of list) {
        if (!options.setBefore || options.setBefore(ary)) {
          resizeDom.style[ary[0]] = ary[1];
          isChange = true;
        }
      }
      isChange && options.onChange && options.onChange(resizeDom);
    }
    const computedWidth = (size) => {
      if (widthUnit === 'vw') {
        return (size / document.body.clientWidth * 100) + 'vw';
      }
      return size + 'px';
    }
    const resizeList = [
      {
        key: '_top',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['top', styT + y + 'px'],
            ['height', styH - y + 'px']
          ])
        }
      },
      {
        key: '_bottom',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['height', styH + y + 'px']
          ])
        }
      },
      {
        key: '_left',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['left', styL + x + 'px'],
            ['width', computedWidth(styW - x)]
          ])
        }
      },
      {
        key: '_right',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['width', computedWidth(styW + x)]
          ])
        }
      },
      {
        key: '_leftTop',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['left', styL + x + 'px'],
            ['width', computedWidth(styW - x)],
            ['top', styT + y + 'px'],
            ['height', styH - y + 'px']
          ])
        }
      },
      {
        key: '_rightTop',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['width', computedWidth(styW + x)],
            ['top', styT + y + 'px'],
            ['height', styH - y + 'px']
          ])
        }
      },
      {
        key: '_leftBottom',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['left', styL + x + 'px'],
            ['width', computedWidth(styW - x)],
            ['height', styH + y + 'px']
          ])
        }
      },
      {
        key: '_rightBottom',
        exec({x, y, styL, styT, styW, styH, resizeDom}) {
          execSetStyle(resizeDom, [
            ['width', computedWidth(styW + x)],
            ['height', styH + y + 'px']
          ])
        }
      }
    ]
    for (const item of resizeList) {
      if (!options.bindBefore || options.bindBefore(item)) {
        const resizeEl = document.createElement('DIV');
        resizeEl.className = `dialog-resize ${item.key}`;
        resizeEl.onmousedown = (e) => {
          if (typeof options.disabled === 'function') {
            if (options.disabled(item)) {
              return false;
            }
          }
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX;
          const disY = e.clientY;

          // 获取到的值带px 正则匹配替换
          let styL, styT, styW, styH;
          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (sty.left.includes('%')) {
            styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
            styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
          } else {
            styL = +sty.left.replace(/\px/g, '');
            styT = +sty.top.replace(/\px/g, '');
          }
          styW = +sty.width.replace(/\px/g, '');
          styH = +sty.height.replace(/\px/g, '');
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            const x = e.clientX - disX;
            const y = e.clientY - disY;
            // 在各个子方法里面重新计算位置
            item.exec({x, y, styL, styT, styW, styH, resizeDom})
          };

          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
          };

        }
        resizeDom.appendChild(resizeEl);
      }
    }
  }
};
