const isIE = () => !isNaN(Number(document.documentMode))

const isEdge = () => navigator.userAgent.indexOf('Edge') > -1

const isFirefox = () => !!window.navigator.userAgent.match(/firefox/i)

/**
 * 专用数据转换方法
 * @param baseData  Object|Array 被转换的数据 后端数据
 * @param option Object 配置对象
 * {
 *   id: 'cmId' // 字符串模式 前端字段与后端字段一一对应即可
 *   label: (v) => `${v.name}·${v.positionName}`  // 函数模式，传入baseData 自行拼接传出
 * }
 * @returns {{}}
 */
function dataChange(baseData, option) {
  if (Array.isArray(baseData)) {
    return baseData.map((v) => dataChange(v, option))
  } else {
    const resultData = {}
    if (baseData) {
      for (const key in option) {
        const target = option[key]
        switch (typeof target) {
          case 'string':
            resultData[key] = baseData[target]
            break
          case 'function':
            resultData[key] = target(baseData)
            break
        }
      }
    }
    return resultData
  }
}

/**
 * YYYY-MM-DD hh:mm:ss
 * AW  AW[MM月DD日] hh:mm  今天 12:05 | 10月11日 12:05
 * AW  AW[MM-DD< hh:mm>]   今天 12:05 | 10月11日
 * [YY年]AW[MM月DD日] hh:mm  今天 20:13 | 10月11日 12:05 | 20年10月11日 12:05
 * 除了MM不区分大小写 HH 补0 H 不补0
 * @param date
 * @param format
 * @param emptyValue 空date返回值
 * @returns {string}
 */
export function formatDate(date, format = 'YYYY-MM-DD hh:mm:ss', emptyValue = '') {
  if (!date) return emptyValue;
  if (typeof date === 'string' && (date.includes('年') || date.includes('至今'))) {
    return date;
  }
  date = getDate(date, emptyValue);
  const weeks = ['日', '一', '二', '三', '四', '五', '六'];
  const W = '周' + weeks[date.getDay()];
  let AW = '';
  let AW1 = '';
  let AWMD = '';
  const pd = v => v < 10 ? `0${v}` : v;
  const YYYY = date.getFullYear(), YY = YYYY.toString().slice(2),
    M = date.getMonth() + 1, MM = pd(M),
    D = date.getDate(), DD = pd(D),
    H = date.getHours(), HH = pd(H),
    m = date.getMinutes(), mm = pd(m),
    S = date.getSeconds(), SS = pd(S);

  if (format.indexOf('AW') > -1) {
    const now = new Date();
    const nowDay = `${now.getFullYear()}${pd(now.getMonth())}${pd(now.getDate())}`;
    const dateDay = `${date.getFullYear()}${pd(date.getMonth())}${pd(date.getDate())}`;
    AW = {'-2': '后天', '-1': '明天', '0': '今天', '1': '昨天', '2': '前天'}[(+nowDay - +dateDay).toString()];
    AW1 = {'-1': '明天', '0': '今天', '1': '昨天'}[(+nowDay - +dateDay).toString()];
    // 支持AW[format]写法 如果命中AW 使用AW 否则替换为format
    let awmIndex = format.indexOf('AW[');
    if (awmIndex === -1) awmIndex = format.indexOf('AW1[');
    const awmLastIndex = awmIndex > -1 ? format.indexOf(']', awmIndex) : -1;
    if (awmLastIndex > -1) {
      const isAw1 = format.indexOf('AW1[') > -1;
      let awmFormat = format.substring(awmIndex + 3 + (isAw1 ? 1 : 0), awmLastIndex);
      const awmOtherFormat = (awmFormat.match(/<(.+?)>/g) || [])[0];
      if (!isAw1 && AW || isAw1 && AW1) {
        format = format.replace(`AW[${awmFormat}]`, 'AW').replace(`AW1[${awmFormat}]`, 'AW1');
        if (awmOtherFormat) format += awmOtherFormat.substring(1, awmOtherFormat.length - 1);
      } else {
        if (awmOtherFormat) awmFormat = awmFormat.replace(awmOtherFormat, '');
        format = format.substring(0, awmIndex) + awmFormat + format.substr(awmLastIndex + 1);
      }
    }
    if (!AW) AW = W;
  }

  const replace = (format) => {
    format = format
      .replace('MM', MM).replace('M', M)
      .replace('mm', mm).replace('m', m);

    format = format.toLocaleUpperCase();

    format = format
      .replace('AWMD', AWMD).replace('AW1', AW1).replace('AW', AW).replace('W', W)
      .replace('YYYY', YYYY).replace('YY', YY)
      .replace('DD', DD).replace('D', D)
      .replace('HH', HH).replace('H', H)
      .replace('SS', SS).replace('S', S);
    return format;
  }

  // 新增超出则展示模式 [YYYY-] 如果与当前时间的格式一样则不展示，否则展示
  if (format.indexOf('[') > -1) {
    const index = format.indexOf('[');
    const lastIndex = format.indexOf(']');
    const deepFormat = format.substring(index + 1, lastIndex);
    if (deepFormat) {
      const deepRes = replace(deepFormat);
      // 相同 去掉
      if (deepRes === formatDate(new Date(), deepFormat)) {
        format = format.substr(0, index) + format.substr(lastIndex + 1);
      }
      // 不同 替换
      else {
        format = format.substr(0, index) + deepFormat + format.substr(lastIndex + 1);
      }
    }
  }
  return replace(format);
}

export function commonToTree(ary, pid = 0, {deep = 0, handle} = {}) {
  const result = [];
  for (const item of ary) {
    if (item.pid === pid) {
      item.text = item.name;
      result.push(item);
      item.children = commonToTree(ary, item.id, {deep: deep + 1, handle});
      if (!item.children.length) delete item.children;
      if (handle) handle(item, {deep: deep + 1});
    }
  }
  return result;
}

/**
 * 判断是否空对象
 * @param object
 * @returns {boolean}
 */
export function isEmptyObject(object) {
  if (object) {
    for (let key in object) {
      return false;
    }
  }
  return true;
}

export function areaSubstr(areas) {
  const AreaKeys = [
    '满族自治县', '满族蒙古族自治县', '蒙古族自治县', '朝鲜族自治县',
    '回族彝族自治县', '彝族回族苗族自治县', '彝族苗族自治县', '土家族苗族自治县', '布依族苗族自治县', '苗族布依族自治县',
    '彝族傣族自治县', '傣族彝族自治县', '仡佬族苗族自治县', '黎族苗族自治县', '苗族侗族自治县', '哈尼族彝族傣族自治县', '哈尼族彝族自治县',
    '彝族哈尼族拉祜族自治县', '傣族拉祜族佤族自治县', '傣族佤族自治县', '拉祜族佤族布朗族傣族自治县', '苗族瑶族傣族自治县', '彝族回族自治县',
    '独龙族怒族自治县', '保安族东乡族撒拉族自治县', '回族土族自治县', '撒拉族自治县', '哈萨克自治县', '塔吉克自治县',
    '回族自治县', '畲族自治县', '土家族自治县', '布依族自治县', '苗族自治县', '瑶族自治县', '侗族自治县', '水族自治县', '傈僳族自治县',
    '仫佬族自治县', '毛南族自治县', '黎族自治县', '羌族自治县', '彝族自治县', '藏族自治县', '纳西族自治县', '裕固族自治县', '哈萨克族自治县',
    '哈尼族自治县', '拉祜族自治县', '佤族自治县',
    '左旗', '右旗', '中旗', '后旗', '联合旗', '自治旗', '旗', '自治县',
    '街道办事处',
    '新区', '区', '县', '市'
  ];

  // TODO 数据不全 暂用
  const TownKeys = [
    '镇', '街道', '乡'
  ]

  let area = areas[2];
  let town = areas[3];
  for (const key of AreaKeys) {
    const index = area ? area.indexOf(key) : -1;
    if (index > -1 && index + key.length === area.length && area.length > 2) {
      areas[2] = area.substr(0, index);
      break;
    }
  }
  for (const key of TownKeys) {
    const index = town ? town.indexOf(key) : -1;
    if (index > -1 && index + key.length === town.length && town.length > 2) {
      areas[3] = town.substr(0, index);
      break;
    }
  }

  return areas;
}

/**
 * 判定2个对象是否一致
 * @param object
 * @param other
 * @returns {boolean}
 */
export function isEqual(object, other) {
  if (object === other) return true;
  if ((typeof object === 'object' && object != null) && (typeof other == 'object' && other != null)) {
    if (Object.keys(object).length !== Object.keys(other).length) return false;
    for (let prop in object) {
      if (!other.hasOwnProperty(prop) || !isEqual(object[prop], other[prop])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function GUID4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function GUID(length) {
  if (!length) {
    return new Date().getTime() + GUID(8);
  } else {
    if (length < 5) {
      return GUID4().substring(4 - length);
    } else {
      const size = Math.ceil(length / 4);
      let guid = '';
      for (let i = 0; i < size; i++) {
        if (size === i + 1) {
          guid += GUID4().substring(size * 4 - length);
        } else {
          guid += GUID4();
        }
      }
      return guid;
    }
  }
}

export function getYear(date) {
  if (date) {
    const now = new Date();
    date = new Date(date);
    let year = 1;
    const nMonth = now.getMonth();
    const oMonth = date.getMonth();
    if (nMonth < oMonth || (nMonth === oMonth && now.getDate() < date.getDate())) {
      year -= 1
    }
    return (now.getFullYear() - date.getFullYear() + year);
  } else {
    return ''
  }
}

// 获得字符串字节长度
export function getCharLength(val) {
  const str = val.toString();
  let bytesCount = 0;
  for (let i = 0, n = str.length; i < n; i++) {
    let c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      bytesCount += 1;
    } else {
      bytesCount += 2;
    }
  }
  return bytesCount;
}

export function getDate(date, emptyValue = '') {
  if (!date) return emptyValue;
  return date instanceof Date ? date : new Date(typeof date === 'number' ? date : date.length < 20 ? date.replace(/-|\./g, '/') : date || 0)
}


export function formatDuration(startDate, endDate, {hiddenMonth = false} = {}) {
  startDate = getDate(startDate);
  if (!startDate) return '';
  endDate = endDate ? getDate(endDate) : new Date();
  let year = endDate.getFullYear() - startDate.getFullYear();
  let month = endDate.getMonth() - startDate.getMonth();
  let day = !year && !month ? endDate.getDate() - startDate.getDate() : 0;
  if (month < 0) {
    year -= 1;
    month = 12 + month;
  }
  year = year > 0 ? `${year}年` : '';
  month = month > 0 ? `${month}个月` : '';
  day = day > 0 ? `${day}天` : '';
  return (year || month) ? `${year}${year && hiddenMonth ? '' : month}` : day;
}

export function formatCountDown(at, format = 'simple') {
  let d = ~~(at / 86400);
  at -= d * 86400;
  let h = ~~(at / 3600);
  at -= h * 3600;
  let m = ~~(at / 60);
  const result = [[d, '天'], [h, '时']];
  if (format === 'simple') {
    return result.filter(v => v[0]).map(v => v.join('')).join('');
  }
  return '';
}

export {isIE, isFirefox, isEdge, dataChange}
