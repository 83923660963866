import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterEach from './router-each.js';
import routes from './package/index.js';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

/**
 * 路由meta规范
 meta: {
    userType: '',                        //限定账号类型验证
    validate: user => !user.sub_account, //自定义验证
    tabs: {               // 自定义路由tabs 设定为false 不在tabs显示这个路由
      title: '',
      icon: '',
      src: '',
    },
  },
 */

RouterEach(router);

export default router;
