import config from '../../config/index.js';

export default {

  // this.$store.state.
  state: {
    title: config.app.name,

    titlePrefix: '淘才-',

    route: {
      stopJump: false,
      to: null,
    },

    formSize: 'small',

    tableSize: '',
  },

  // this.$store.dispatch('', {})
  actions: {},

  // this.$store.commit('', {})
  mutations: {
    routeTo(state, to) {
      state.route.to = to;
    },
    routeStopJump(state, status) {
      state.route.stopJump = status;
    },
  },

  // this.$store.getters.
  getters: {},
};
