import CryptoJS from './crypto-js.min.js';

function encryptByDES(payload, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.DES.encrypt(payload, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}

function decryptByDES(ciphertext, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Hex.parse(ciphertext)
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

/**
 * 规范请求头
 * @param header
 * @param saas
 * @param config
 * @returns {{'content-type': string, 'system-payload': *, 'agent-key': *}}
 */
export function requestHeader({header, saas, config}) {
  const app = config.app;
  // const payload = [~~(Date.now() / 1000), app.aaid, app.productType, app.terminalType, app.version].join('_');
  // const payloadDes = encryptByDES(payload, app.agentKey);
  return {
    'content-type': 'application/json',
    ...(header !== false && !saas ? {
      ...(header || {})
    } : {})
  }
}
