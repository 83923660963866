import Vue from 'vue';
import Vuex from 'vuex';

import main from './package/main.js';

Vue.use(Vuex);

const store = new Vuex.Store({

  state: {
    ...main.state
  },

  actions: {
    ...main.actions
  },

  mutations: {
    ...main.mutations
  },

  getters: {
    ...main.getters
  }

});

export default store;
