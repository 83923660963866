import {requestHeader} from '../rsa/des-header.js';
import requestRsa from '../rsa/index.js';
import config from '../../config/index.js';
import axios from 'axios';
import storage from 'store';
import {Modal} from 'ant-design-vue';

/**
 * 后端的规范返回体
 * @param assign 可根据需要字段替换
 * @returns {{msg: string, code: number, data: null}}
 */
function responseData(assign = {}) {
  if (assign.resp_code && !assign.code) assign.code = assign.resp_code;
  if (assign.resp_msg && !assign.msg) assign.msg = assign.resp_msg;
  const response = Object.assign({
    msg: '',
    code: config.server.successCode,
    data: null
  }, assign);
  response.code = +response.code
  return response
}

/**
 * 封装统一请求函数
 */
function request(params) {
  return requestByNodeJs(params)
}

function requestByNodeJs(
  {
    url,
    api,
    data = {},
    timeout = config.server.timeout,
    errorModal = true,
    errorModalCompleteReject = false,
    loading = false,
    sendAuthorization = true,
    rsa = false,
    app = false,
    saas = false,
    header
  } = {}) {
  return new Promise((resolve, reject) => {
    // loading && tcy.showLoading(typeof loading == 'string' ? loading : '加载中');
    const headers = requestHeader({header, saas, config});

    if (sendAuthorization) {
      const token = storage.get(config.storage.authorizationKey);
      if (token) headers['Authorization'] = `${token.access_token}`;
    }
    // 前置RSA加密
    requestRsa.encrypt(data, {rsa, request}).then(data => {
      return axios({
        method: 'POST',
        url: url || (saas ? config.server.node.saasUrl : config.server.node.url) + api,
        data: data,
        headers,
        timeout
      }).then(res => {
        if (!res.data.RSA) return res;
        // 后置RSA解密
        return requestRsa.decrypt(res.data.RSA, {request}).then(data => {
          delete res.data.RSA;
          res.data.data = data;
          return res;
        })
      })
    }).then(res => {
      const data = responseData(res.data);
      if (data.code === config.server.successCode) {
        resolve(data);
      } else {
        errorModal = [1006, 1007].includes(data.code) ? false : typeof errorModal === 'function' ? errorModal(data) : errorModal;
        if (errorModal) {
          Modal.error({
            title: `错误 ${data.code}`,
            content: data.msg || '未知错误'
          });
        }
        reject(data);
      }
    }).catch(err => {
      const data = err.response && err.response.data ? responseData(err.response.data) : err;
      errorModal = typeof errorModal === 'function' ? errorModal(data) : errorModal;
      if (errorModal) {
        let content = data.msg || err.message || '未知错误';
        if (content.indexOf('timeout') === 0) content = '请求超时了'
        Modal.error({
          title: `错误 ${data.code}`,
          content
        });
      }
      console.log([err]);
      reject(err);
    }).finally(() => {
      //loading && tcy.hideLoading();
    });
  })
}

function requestByNodeJs1(
  {
    url,
    api,
    data = {},
    timeout = config.server.timeout,
    errorModal = true,
    errorModalCompleteReject = false,
    loading = false,
    sendAuthorization = true,
    header = {}
  } = {}) {
  return new Promise((resolve, reject) => {
    // loading && tcy.showLoading(typeof loading == 'string' ? loading : '加载中');
    header = {
      'content-type': 'application/json',
/*      'agent-key': config.app.agentKey,
      'product-type': config.app.productType,
      'terminal-type': config.app.terminalType,*/
      ...header
    }

    if (sendAuthorization) {
      const token = storage.get(config.storage.authorizationKey);
      if (token) header['Authorization'] = `${token.access_token}`;
    }
    axios({
      method: 'POST',
      url: url || config.server.node.url + api,
      data: data,
      headers: header,
      timeout
    }).then(res => {
      const data = responseData(res.data);
      if (data.code === config.server.successCode) {
        resolve(data)
      } else {
        errorModal = typeof errorModal === 'function' ? errorModal(data) : errorModal;
        if (errorModal) {
          Modal.error({
            title: `错误 ${data.code}`,
            content: data.msg || '未知错误'
          });
        }
        reject(data);
      }
    }).catch(err => {
      const data = err.response && err.response.data ? responseData(err.response.data) : err;
      errorModal = typeof errorModal === 'function' ? errorModal(data) : errorModal;
      if (errorModal) {
        Modal.error({
          title: `错误 ${data.code}`,
          content: data.msg || err.message || '未知错误'
        });
      }
      console.log([err]);
      reject(err);
    }).finally(() => {
      //loading && tcy.hideLoading();
    });
  })
}

export {responseData, request}
